import { Link } from "react-router-dom";
import "../styling/HomePage.css";
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

export function HomePage() {
  const category = "behandlingar";

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="home-hero">
        <div className="hero-text">
          <h1>Ett mini-spa mitt i kohagen</h1>
          <h3>Lantligt, rogivande, miljövänligt, personligt och lyxigt.</h3>
        </div>
      </div>
      <div className="home-wrapper">
        <Helmet>
          <title>Spaladan - ett minispa i kohagen</title>
          <meta
            name="description"
            content="Välkommen till ett spa i lantlig miljö. Här kan du unna dig lite lyx och avkoppling i vardagen med
              avslappnande och närande behandlingar"
          />
        </Helmet>
        <div className="intro-section">
          <div className="welcome">
            <h2>Välkommna till Spa Ladan</h2>
            <p>
              Här kan du unna dig lite lyx och avkoppling i vardagen med
              avslappnande och närande behandlingar. Samtliga med ekologiska
              produkter från Maria Åkerberg.
            </p>
            <p>
              Vi har byggt om den 150 år galma lagården/ladan till ett mini-Spa.
              Vårt mål är att skapa en miljö som tillsammans med den omgivande
              naturen skapar lugn och harmoni. Genom att bevara ladans själ och
              mixa med modern teknik skapas en plats för lyx och avkoppling.
            </p>

            <p>Här kan du och dina vänner umgås, relaxa och njuta av lugnet.</p>

            <p>
              Boka våra härliga Spa-paket med eller utan övernattning. Alltid
              bara ett sällskap i taget, och ni har hela Spaladan för er själva,
              med mat, dryck, Spa-bad och Bastu.
            </p>
          </div>
          <span className="buttons">
            <Link to="/boka">
              {" "}
              <button>BOKA</button>
            </Link>
            <a
              href="https://www.google.com/maps/place/Spa-Ladan/@57.9382878,16.4587524,10z/data=!4m6!3m5!1s0x46585f6bfb50ef61:0x167176b92bd5f020!8m2!3d57.9187409!4d16.7344372!16s%2Fg%2F11gntv9d8m?entry=ttu"
              target="_blank"
            >
              <button>HITTA HIT</button>
            </a>
          </span>
        </div>

        <div className="card-section">
          <img src="/assets/kikar.JPG" width="800px" alt="kikar"></img>
          <div className="card-text">
            <h2>Spa-Paket</h2>
            <p>
              Pausa vardagslivet för en stund! Här kan du och dina vänner umgås,
              relaxa och njuta av lungnet.
            </p>
            <p>
              Alltid bara ett sällskap i taget - ni har hela huset för er själva
              med mat, dryck spa-bad och bastu.
            </p>

            <Link to="/spapaket">
              <button>Läs mer </button>
            </Link>
          </div>
        </div>
        <div className="card-section">
          <img
            src="/assets/behandling.JPG"
            width="800px"
            alt="behandling"
          ></img>
          <div className="card-text">
            <h2>Behandlingar</h2>
            <p>
              Hos Spa-ladan kan du välja mellan ett stort urval av behandlingar
              för ansiktet, kroppen eller fötterna. I alla behandlingar används
              ekologiska produkter från Maria Åkerberg.
            </p>
            <p>Du kan även färga fransar och bryn samt göra lashlift.</p>

            <Link to={`/behandlingar/${category}`}>
              <button>Se alla behandlingar</button>
            </Link>
          </div>
        </div>
        <div className="tripple">
          <div className="mini-card">
            <h2>Hudvårdsbutik</h2>
            <img src="/assets/spakväll.jpg" alt="hudvårdsbutik"></img>
            <p>
              I butiken finner du fantastiska produkter från Maria Åkerberg.
            </p>

            <Link to="/hudvardsbutik">
              {" "}
              <button>Läs mer </button>
            </Link>
          </div>
          <div className="mini-card">
            <h2>Omgivning</h2>
            <img src="/assets/havet.jpg" alt="omgivning"></img>
            <p>Passa på att upptäcka och njuta av den närliggande naturen.</p>

            <Link to="/omgivning">
              <button>Läs mer</button>
            </Link>
          </div>
          <div className="mini-card">
            <h2>Byggprojekt</h2>
            <img src="/assets/ladan.jpg" alt="byggprojektet"></img>
            <p>Följ byggprocessen från start till mål.</p>
            <br></br>

            <Link to="/projekt">
              <button>Läs mer </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
